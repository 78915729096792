import { MyRoutes } from "./router/MyRoutes";

function App() {
  return (
    <div className="App">
      <MyRoutes/>
    </div>
  );
}

export default App;
